import React from "react"

import "../styles/object/component/typography.scss"
import "../styles/object/project/index.scss"

import Layout from "../components/layout"
import Cta from "../components/cta"
import CardBusiness from "../components/cardBusiness"
import {Link} from "gatsby"
import {library} from "@fortawesome/fontawesome-svg-core" //fontawesomeのコアファイル
import {fab} from "@fortawesome/free-brands-svg-icons" //fontawesomeのbrandアイコンのインポート
import {fas} from "@fortawesome/free-solid-svg-icons" //fontawesomeのsolidアイコンのインポート
import {far} from "@fortawesome/free-regular-svg-icons"
import define from "../config/define"; //fontawesomeのregularアイコンのインポート
library.add(fab, fas, far) //他のコンポーネントから簡単に呼び出せるようにするための登録処理

export default () => (
    <Layout>
        <main className="l-main">
            <div className="top">
                <div className="topcopyContainer">
                    <div className="topcopy">
                        <h2 className="topcopy_main">
                            <span>For The</span>
                            <span>Realization</span>
                            <span>Of A Universally</span>
                            <span>Evolving Society</span>
                        </h2>
                        <h3 className="topcopy_sub">普遍的に進化する社会の実現へ</h3>
                    </div>
                </div>
            </div>
            <section className="index">
                <div className="bodycopyContainer">
                    <div className="bodycopy">
                        <div className="bodycopy_massage">
                            <p>
                                プラミナスは生物が未知の領域を開拓するとき、自身を環境に適合させ進化と退化を繰り返すことで、多様性や革新的な能力を獲得してきたように、流動的な社会に合わせたテクノロジーと常識にとらわれない手法で普遍的に進化する社会の実現を目指します。
                            </p>
                            <Link to={`/about`} className="c-linkBorder bodycopy_link">
                                {" "}
                                READ MORE
                            </Link>
                        </div>
                    </div>
                    <img
                        src="top02.jpg"
                        alt="ボディーコピーのイメージ写真"
                        className="bodycopyContainer_image"
                    />
                </div>
            </section>
            <section className="business">
                <div className="c-heading">
                    <h4 className="c-heading_main">BUSINESS</h4>
                    <h5 className="c-heading_sub">事業内容</h5>
                </div>
                <div className="container">
                    <CardBusiness
                        imageName="top03.jpg"
                        imageAlt="システム開発"
                        title="システム開発"
                        company=""
                        tags={["企画", "設計", "開発", "検証", "運用・保守"]}
                        content="クラウド及び、オンプレミスなバックエンドシステム構築。WEBを中心にしたマルチデバイス（スマホ/タブレット/PC WEB/デスクトップアプリ）のフロントエンドの開発を得意とする。"
                    />
                    <CardBusiness
                        imageName="top04.jpg"
                        imageAlt="WEB制作"
                        title="WEB制作"
                        company=""
                        tags={["コーポレートサイト", "ランディングサイト", "CMS", "写真・動画撮影"]}
                        content="企画/Webサービス/UI・UX/業務システム/アプリ等のシステム構築。"
                    />
                    <div className="business_more_btn">
                        <Link to={`/business`} className="c-linkBorder">
                            {" "}
                            READ MORE
                        </Link>
                    </div>
                </div>
            </section>
            <section className="news">
                <div className="c-heading">
                    <h4 className="c-heading_main">NEWS</h4>
                    <h5 className="c-heading_sub">お知らせ</h5>
                </div>
                <div className="container">
                    <dl>
                        {define.corp.notices.map(notice =>
                            <>
                                <dt>{notice.date}</dt>
                                <dd>{notice.desc}</dd>
                            </>
                        )}
                    </dl>
                </div>
            </section>
            <section className="introduction introduction--company">
                <img
                    src="bg_introduction_company.png"
                    alt="会社案内の背景"
                    className="introduction_background introduction_background--company"
                    data-sal="slide-right"
                    data-sal-duration="800"
                    data-sal-delay="200"
                    data-sal-easing="easeOutElastic"
                    width="654px"
                    height="832px"
                />
                <div
                    className="introductionContainer"
                    data-sal="slide-left"
                    data-sal-duration="800"
                    data-sal-delay="200"
                    data-sal-easing="easeOutElastic"
                >
                    <div className="c-heading">
                        <h4 className="c-heading_main">COMPANY</h4>
                        <h5 className="c-heading_sub">会社案内</h5>
                    </div>
                    <p className="introductionContainer_description">
                        plmin.usの会社概要、事業内容、アクセス情報などご案内しています。
                    </p>
                    <Link to={`/company`} className="c-linkBorder">
                        {" "}
                        READ MORE
                    </Link>
                </div>
            </section>
            <section className="introduction introduction--recruit">
                <img
                    src="bg_introduction_recruit.png"
                    alt="採用情報の背景"
                    className="introduction_background introduction_background--recruit"
                    data-sal="slide-left"
                    data-sal-duration="800"
                    data-sal-delay="200"
                    data-sal-easing="easeOutElastic"
                    width="700px"
                    height="859px"
                />
                <div
                    className="introductionContainer"
                    data-sal="slide-right"
                    data-sal-duration="800"
                    data-sal-delay="200"
                    data-sal-easing="easeOutElastic"
                >
                    <div className="c-heading">
                        <h4 className="c-heading_main">RECRUIT</h4>
                        <h5 className="c-heading_sub">採用情報</h5>
                    </div>
                    <p className="introductionContainer_description">
                        現在エンジニアとデザイナーを積極的に募集しています。<br />
                        チャレンジできる環境がplmin.usにはあります。
                    </p>
                    <Link to={`/recruit`} className="c-linkBorder">
                        {" "}
                        READ MORE
                    </Link>
                </div>
            </section>
        </main>
        <Cta/>
    </Layout>
)
